import React from "react";
import {
	H1,
	H2,
	FlexCenteredContainer,
	BackgroundContainer,
	VERTICAL_HEIGHT_PADDING,
} from "../theme";
import NavigationBar from "../components/NavigationBar";

const NotFoundPage = () => (
	<BackgroundContainer>
		<NavigationBar />
		<FlexCenteredContainer>
			<div
				style={{
					paddingTop: VERTICAL_HEIGHT_PADDING,
				}}
			>
				<FlexCenteredContainer>
					<H1>Error 404 - Page Not Found</H1>
				</FlexCenteredContainer>
				<FlexCenteredContainer>
					<H2>Please choose another page</H2>
				</FlexCenteredContainer>
			</div>
		</FlexCenteredContainer>
	</BackgroundContainer>
);

export default NotFoundPage;
