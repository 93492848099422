import React from "react";
import NavigationBar from "../components/NavigationBar";
import {
	ParagraphText,
	FlexCenteredContainer,
	BackgroundContainer,
	H1,
	LAYOUT_PADDING,
} from "../theme";

const Projects = () => (
	<BackgroundContainer>
		<NavigationBar />
		<FlexCenteredContainer>
			<div>
				<FlexCenteredContainer style={{ padding: LAYOUT_PADDING }}>
					<H1>projects</H1>
				</FlexCenteredContainer>
				<FlexCenteredContainer>
					<ParagraphText>
						<span role="img" aria-label="hourglass not done emoji">
							coming soon ⏳
						</span>
					</ParagraphText>
				</FlexCenteredContainer>
			</div>
		</FlexCenteredContainer>
	</BackgroundContainer>
);

export default Projects;
