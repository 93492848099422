import React from "react";
import NavigationBar from "../components/NavigationBar";
import {
	ParagraphText,
	FlexCenteredContainer,
	BackgroundContainer,
	H1,
	LAYOUT_PADDING,
} from "../theme";

const About = () => (
	<BackgroundContainer>
		<NavigationBar />
		<FlexCenteredContainer>
			<div>
				<FlexCenteredContainer style={{ padding: LAYOUT_PADDING }}>
					<H1>about</H1>
				</FlexCenteredContainer>
				<FlexCenteredContainer>
					<ParagraphText>
						I'm a software engineer from the UK.
					</ParagraphText>
				</FlexCenteredContainer>
				<FlexCenteredContainer>
					<ParagraphText>
						This website is still in construction for the most part,
						and just for fun!
					</ParagraphText>
				</FlexCenteredContainer>
				<FlexCenteredContainer>
					<ParagraphText>
						I mainly have experience with JS, React, Python, C++,
						C#, SQL & NoSQL.
					</ParagraphText>
				</FlexCenteredContainer>
			</div>
		</FlexCenteredContainer>
	</BackgroundContainer>
);

export default About;
