import React from "react";
import PropTypes from "prop-types";
import { COLOURS, StickyElementsContainer } from "../theme";

const Layout = (props) => (
	<StickyElementsContainer
		style={{
			backgroundColor: COLOURS.backgroundColour,
		}}
	>
		{props.children}
	</StickyElementsContainer>
);

Layout.propTypes = {
	children: PropTypes.node.isRequired,
};

export default Layout;
