import React from "react";
import StyledLink from "../components/StyledLink";
import {
	H2,
	FlexCenteredContainer,
	BackgroundContainer,
	TitleText,
	VERTICAL_HEIGHT_PADDING,
	LAYOUT_PADDING,
	COLOURS,
} from "../theme";

const Home = () => (
	<BackgroundContainer>
		<FlexCenteredContainer>
			<div
				style={{
					paddingTop: VERTICAL_HEIGHT_PADDING,
				}}
			>
				<FlexCenteredContainer>
					<TitleText>anthony wisdom</TitleText>
				</FlexCenteredContainer>
				<FlexCenteredContainer
					style={{
						paddingBottom: LAYOUT_PADDING,
					}}
				>
					<H2>software engineer</H2>
				</FlexCenteredContainer>

				<FlexCenteredContainer
					style={{
						fontSize: "2vw",
					}}
				>
					<StyledLink color={COLOURS.textColour} to="/about">
						about
					</StyledLink>
					<StyledLink color={COLOURS.textColour} to="/projects">
						projects
					</StyledLink>
					<StyledLink color={COLOURS.textColour} to="/blog">
						blog
					</StyledLink>
					<StyledLink color={COLOURS.textColour} to="/contact">
						contact
					</StyledLink>
				</FlexCenteredContainer>
			</div>
		</FlexCenteredContainer>
	</BackgroundContainer>
);

export default Home;
