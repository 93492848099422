import React from "react";
import StyledLink from "./StyledLink";
import { StickyElement, COLOURS } from "../theme";
import logo from "../images/Wlogo.png";
import Navbar from "react-bootstrap/Navbar";

const smallDisplayPictureWidth = "25px";

const NavigationBar = () => (
	<StickyElement style={{ backgroundColor: COLOURS.navbarBackground }}>
		<Navbar collapseOnSelect expand="sm" variant="dark">
			<Navbar.Brand href="/">
				<div
					style={{
						display: "flex",
					}}
				>
					<div>
						<img
							src={logo}
							alt="W Logo"
							width={smallDisplayPictureWidth}
							height={smallDisplayPictureWidth}
							style={{ marginRight: "15px" }}
						/>
					</div>
					<div>anthony wisdom</div>
				</div>
			</Navbar.Brand>
			<Navbar.Toggle aria-controls="responsive-navbar-nav" />
			<Navbar.Collapse id="responsive-navbar-nav">
				<div style={{ display: "flex" }}>
					<StyledLink to="/about">about</StyledLink>
					<StyledLink to="/projects">projects</StyledLink>
					<StyledLink to="/blog">blog</StyledLink>
					<StyledLink to="/contact">contact</StyledLink>
				</div>
			</Navbar.Collapse>
		</Navbar>
	</StickyElement>
);

export default NavigationBar;
