import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import Layout from "./hocs/Layout";
import Home from "./pages/Home";
import About from "./pages/About";
import Projects from "./pages/Projects";
import Contact from "./pages/Contact";
import Blog from "./pages/Blog";
import NotFoundPage from "./pages/NotFoundPage";
import "bootstrap/dist/css/bootstrap.min.css";

class App extends React.Component {
	render() {
		return (
			<Router>
				<Layout>
					<Routes>
						<Route path="*" element={<NotFoundPage />} />
						<Route exact path="/" element={<Home />} />
						<Route exact path="/about" element={<About />} />
						<Route exact path="/projects" element={<Projects />} />
						<Route exact path="/blog" element={<Blog />} />
						<Route exact path="/contact" element={<Contact />} />
					</Routes>
				</Layout>
			</Router>
		);
	}
}

export default App;
