import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { COLOURS, TEXT_SHADOW, FONT_FAMILY } from "../theme";

const StyledLink = ({ to, children, color = COLOURS.mainColour }) => {
	return (
		<Link
			style={{
				color: color,
				textShadow: TEXT_SHADOW[1],
				fontFamily: FONT_FAMILY,
				textDecoration: "none",
				display: "block",
				padding: ".5rem .75rem",
				fontWeight: "bold",
			}}
			to={to}
		>
			{children}
		</Link>
	);
};

StyledLink.propTypes = {
	to: PropTypes.string.isRequired,
	children: PropTypes.node.isRequired,
	color: PropTypes.string,
};

export default StyledLink;
