import styled from "@emotion/styled";

export const FONT_FAMILY = "'Roboto', sans-serif";

export const LAYOUT_PADDING = "20px";

export const VERTICAL_HEIGHT_PADDING = "25vh";

export const BORDER_RADIUS = "20px";

export const COLOURS = {
	mainColour: "#7c259c",
	textColour: "white",
	backgroundColour: "#161142",
	navbarBackground: "black",
};

export const TEXT_SHADOW = ["1px 1px 5px #FFF"];

export const StickyElement = styled.div`
	position: -webkit-sticky;
	position: sticky;
	top: 0;
`;

export const StickyElementsContainer = styled.div({
	margin: "0 auto",
	":after": {
		content: "''",
		clear: "both",
	},
	":before": {
		content: "''",
		clear: "both",
	},
});

export const ParagraphText = styled.div`
	font-family: ${FONT_FAMILY};
	color: ${COLOURS.textColour};
	font-style: normal;
	font-variant: normal;
	font-weight: 400;
	font-size: 20px;
`;

export const SmallText = styled.div`
	font-family: ${FONT_FAMILY};
	color: ${COLOURS.textColour};
	font-style: normal;
	font-variant: normal;
	font-weight: 400;
	font-size: 16px;
`;

export const TitleText = styled.div`
	font-family: ${FONT_FAMILY};
	text-shadow: ${TEXT_SHADOW[0]};
	color: ${COLOURS.mainColour};
	font-style: italic;
	font-variant: normal;
	font-weight: 300;
	font-size: 7vw;
`;

export const H1 = styled.div`
	font-family: ${FONT_FAMILY};
	text-shadow: ${TEXT_SHADOW[0]};
	color: ${COLOURS.mainColour};
	font-style: italic;
	font-variant: normal;
	font-weight: 300;
	font-size: 5vw;
`;

export const H2 = styled.div`
	font-family: ${FONT_FAMILY};
	text-shadow: ${TEXT_SHADOW[0]};
	color: ${COLOURS.mainColour};
	font-style: normal;
	font-variant: normal;
	font-weight: 400;
	font-size: 3vw;
`;

export const FlexCenteredContainer = styled.div`
	display: flex;
	justify-content: center;
`;

export const BackgroundContainer = styled.div`
	background-image: ${(props) => props.backgroundImage};
	background-repeat: no-repeat;
	background-size: cover;
	min-height: 100vh;
`;

export const FlexSpacedContainer = styled.div`
	display: flex;
	justify-content: space-between;
`;

export const EmptyContainer = styled.div``;
